import { HTTP, getToken } from './default'

export default {
    getAllCompanies({ordering = null, page = 1} = new Object()) {
        return HTTP({
            url: '/recruiter/company/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            params: {
                ordering,
                page
            }
        })
    },
    getCompany(id) {
        return HTTP({
            url: `/recruiter/company/${id}/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })

    },
    editCompany({ id, data }) {
        return HTTP({
            url: `/recruiter/company/${id}/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    getAllVacancies({ordering = null, page = 1, status = ''} = new Object()) {
        return HTTP({
            url: '/recruiter/vacancy/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            params: {
                ordering,
                page,
                status
            }
        })
    },
    getVacancy(id) {
        return HTTP({
            url: `/recruiter/vacancy/${id}/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })

    },
    editVacancy({ id, data }) {
        return HTTP({
            url: `/recruiter/vacancy/${id}/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    editVacancyStatus(objects_update) {
        return HTTP({
            url: '/recruiter/vacancy/update/',
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: {
                objects_update
            }
        })
    },
    createVacancy(data) {
        return HTTP({
            url: '/recruiter/vacancy/',
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    removeVacancy(id) {
        return HTTP({
            url: `/recruiter/vacancy/${id}/`,
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    removeVacancies(ids) {
        return HTTP({
            url: '/recruiter/vacancy/delete_selected/',
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: {
                ids
            }
        })
    },
    getAllResponses({ordering = null, page = 1, status = '', favourite = null} = new Object()) {
        return HTTP({
            url: '/recruiter/vacancy_response/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            params: {
                ordering,
                page,
                status,
                favourite
            }
        })
    },
    getResponse(id) {
        return HTTP({
            url: `/recruiter/vacancy_response/${id}/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    removeResponse(id) {
        return HTTP({
            url: `/recruiter/vacancy_response/${id}/`,
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    removeResponses(ids) {
        return HTTP({
            url: '/recruiter/vacancy_response/delete_selected/',
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: {
                ids
            }
        })
    },
    sendResponse(data) {
        return HTTP({
            url: '/recruiter/vacancy_response/',
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: {
                request_vacancy_user: data.id,
                comment: data.comment,
                answer: data.answer
            }
        })
    },
    changeResponse({ id, data }) {
        return HTTP({
            url: `/recruiter/vacancy_response/${id}/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    changeResponseStatus(objects_update) {
        return HTTP({
            url: '/recruiter/vacancy_response/update/',
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: {
                objects_update
            }
        })
    },
    downloadPDF(id) {
        return HTTP({
            url: `/recruiter/download/proposal/${id}/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    getAutoResponse() {
        return HTTP({
            url: '/common/autoresponse/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    getAllReCompanies({ company_name = '', city = '' } = new Object()) {
        return HTTP({
            url: '/recruiter/search/company/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            params: {
                company_name,
                city
            }
        })
    },
    getKeys(pageNumber,pageSize) {
        return HTTP({
            url: `/recruiter/credentials/?page=${pageNumber ? pageNumber : 1}${pageSize ? `&page_size=${pageSize}`: ''}`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    createKey() {
        return HTTP({
            url: '/recruiter/credentials/',
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
        })
    },
    removeKey(id) {
        return HTTP({
            url: `/recruiter/credentials/${id}/`,
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
}
