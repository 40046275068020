import { helpers } from 'vuelidate/lib/validators';

const onlyLatinForEmail = helpers.regex('onlyLatinForEmail', /^[a-zA-Z0-9_@\.]*$/)
const alphaEduFac = helpers.regex('alphaEduFac', /^[0-9a-zа-яё]{1}[\s\-a-zа-яё0-9\.\"\,\(\)\«\»]*$/i)
const onlyAlpha = helpers.regex('onlyAlpha', /^[a-zA-Zа-яА-Яё]*$/)
const alphaNumber = helpers.regex('alphaNumber', /^[a-zA-Z0-9_]+$/)
const password = helpers.regex('password', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[?\/<~#`!@$%^&*()+-=}|:\";\',>{ -]).{8,}/)
const onlyNumber = helpers.regex('onlyNumber', /^[1-9]\d*$/)
const alphaSpace = helpers.regex('alphaSpace', /^[a-zA-Zа-яА-ЯёЁ]{1}[\s\-\,\.a-zA-Zа-яА-ЯёЁ]*$/)
const Phone = helpers.regex('Phone', /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/)
const Birthday = helpers.regex('Birthday', /^([0-9]|[012][0-9]|3[01]).(0[1-9]|1[012]).(194[5-9]|19[5-9]\d|200\d|201\d|202[0-9])$/)
const WPYear = helpers.regex('WPYear', /.(19[8-9][0-9]|20([01][0-9]|20|1[0-9]|20|2[0-9]))/)
const WPMonth = helpers.regex('WPMonth', /^([0-9]|1[0-2]?)./)
const onlyURL = helpers.regex('onlyURL', /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)
const forLiftWR = helpers.regex('forLiftWR', /^((5500)|(5[0-4][0-9]{2})|([1-4][0-9]{3})|([1-9][0-9]{2})|([1-9][0-9]{1})|([1-9]))$/)


const birthdayUntil = (value) => {
    let [day, month, year] = value.split('.');

    if (day && month && year) {
        if ((parseInt(day) && parseInt(day) <= 31) &&
            (parseInt(month) && parseInt(month) <= 12) &&
            (parseInt(year) && parseInt(year) >= 1900 && parseInt(year) <= 2020 )) return true
        else return false
    }
}

export {
    onlyLatinForEmail,
    birthdayUntil,
    onlyAlpha,
    onlyNumber,
    alphaNumber,
    alphaSpace,
    Phone,
    Birthday,
    WPYear,
    WPMonth,
    alphaEduFac,
    forLiftWR,
    onlyURL,
    password
}
