import { HTTP, getToken } from './default';

export default {
	// getCourses({ page = 0, name = '', section = 0 } = new Object()) {
	//     let params = {
	//         name,
	//         section: section ? section : ''
	//     }
	//     if (page) params.page = page
	getCourses(params) {
		if (getToken()) {
			return HTTP({
				url: '/teachbase/courses/',
				method: 'GET',
				headers: {
					Authorization: `Token ${getToken()}`
				},
				params
			});
		} else {
			return HTTP({
				url: '/teachbase/courses/',
				method: 'GET',
				params
			});
		}
	},
	getPrograms(params) {
		if (getToken()) {
		return HTTP({
			url: `/program/?direction=${params}`,
			method: 'GET',
			headers: {
				Authorization: `Token ${getToken()}`
			}
		});
	} else {
		return HTTP({
			url: `/program/?direction=${params}`,
			method: 'GET',
		});
	}
	},
	getPopularCourses() {
		if (getToken()) {
			return HTTP({
				url: '/teachbase/courses/popular_courses/',
				method: 'GET',
				headers: {
					Authorization: `Token ${getToken()}`
				}
			});
		} else {
			return HTTP({
				url: '/teachbase/courses/popular_courses/',
				method: 'GET'
			});
		}
	},
	getCourse(id) {
		if (getToken()) {
			return HTTP({
				url: `/teachbase/course/${id}/`,
				method: 'GET',
				headers: {
					Authorization: `Token ${getToken()}`
				}
			});
		} else {
			return HTTP({
				url: `/teachbase/course/${id}/`,
				method: 'GET'
			});
		}
	},
	getProgram(slug) {
		if (getToken()) {
			return HTTP({
				url: `/program/${slug}/`,
				method: 'GET',
				headers: {
					Authorization: `Token ${getToken()}`
				}
			});
		} else {
			return HTTP({
				url: `/program/${slug}/`,
				method: 'GET'
			});
		}
	},
	getTags() {
		return HTTP({
			url: '/teachbase/tags/',
			method: 'GET'
		});
	},
	getSections(params) {
		if (getToken()) {
			return HTTP({
				url: '/teachbase/sections/',
				method: 'GET',
				headers: {
					Authorization: `Token ${getToken()}`
				},
				params
			});
		} else {
			return HTTP({
				url: '/teachbase/sections/',
				method: 'GET',
				params
			});
		}
	},
	getTypesCourses(params) {
		if (getToken()) {
			return HTTP({
				url: '/teachbase/sections/coursetype/',
				method: 'GET',
				headers: {
					Authorization: `Token ${getToken()}`
				},
				params
			});
		} else {
			return HTTP({
				url: '/teachbase/sections/coursetype/',
				method: 'GET',
				// params
			});
		}
	},
	getTopics() {
		if(getToken()) {
			return HTTP({
				url: '/teachbase/sections/coursetopics/',
				method: 'GET',
				headers: {
					Authorization: `Token ${getToken()}`
				},
			});
		} else {
			return HTTP({
				url: '/teachbase/sections/coursetopics/',
				method: 'GET'
			});
		}
	},
	getVacancies(
		{
			page = 1,
			cities = [],
			vacancy_name = '',
            visible_to = '',
			specialisation_vacancy = [],
			field_activity_vacancy = [],
			companies = [],
			type_employment = []
		} = new Object()
	) {
		return HTTP({
			url: '/teachbase/vacancies/',
			method: 'GET',
			params: {
				page,
				city: cities.length ? cities.join() : '',
				company_name: companies.length ? companies.join() : '',
				vacancy_name: vacancy_name ? vacancy_name : '',
                visible_to: visible_to ? visible_to : '',
				specialisation_vacancy: specialisation_vacancy.length
					? specialisation_vacancy.join()
					: '',
				field_activity_vacancy: field_activity_vacancy.length
					? field_activity_vacancy.join()
					: '',
				type_employment: type_employment.length
					? type_employment.join()
					: ''
			}
		});
	},
	getOrganization(id) {
		return HTTP({
			url: `/teachbase/activ/${id}/`,
			method: 'GET'
		});
	},
	getVacanciesOrganization(
		{
			id = '',
			page = 1,
			type_employment = [],
			field_activity_vacancies = [],
			specialisation_vacancies = [],
			companies_vacancies = [],
			cities_vacancies = []
		} = new Object()
	) {
		return HTTP({
			url: `/teachbase/activ/${id}/vacancyes/`,
			method: 'GET',
			params: {
				page,
				vacancyes_city: cities_vacancies.length
					? cities_vacancies.join()
					: '',
				vacancyes_company: companies_vacancies.length
					? companies_vacancies.join()
					: '',
				vacancyes_specialisation: specialisation_vacancies.length
					? specialisation_vacancies.join()
					: '',
				vacancyes_field_activity: field_activity_vacancies.length
					? field_activity_vacancies.join()
					: '',
				type_employment: type_employment.length
					? type_employment.join()
					: ''
			}
		});
	},
	getOrganizationCities({ id = '', type_employment = [] } = new Object()) {
		return HTTP({
			url: `/teachbase/activ/${id}/cityes/`,
			method: 'GET',
			params: {
				type_employment: type_employment.length
					? type_employment.join()
					: ''
			}
		});
	},
	getOrganizationActivities(
		{ id = '', type_employment = [] } = new Object()
	) {
		return HTTP({
			url: `/teachbase/activ/${id}/field_activity/`,
			method: 'GET',
			params: {
				type_employment: type_employment.length
					? type_employment.join()
					: ''
			}
		});
	},
	getOrganizationCompanies({ id = '', type_employment = [] } = new Object()) {
		return HTTP({
			url: `/teachbase/activ/${id}/company/`,
			method: 'GET',
			params: {
				type_employment: type_employment.length
					? type_employment.join()
					: ''
			}
		});
	},
	getOrganizationSpecialisation(
		{ id = '', type_employment = [] } = new Object()
	) {
		return HTTP({
			url: `/teachbase/activ/${id}/specialisation/`,
			method: 'GET',
			params: {
				type_employment: type_employment.length
					? type_employment.join()
					: ''
			}
		});
	},
	getOrganizationTypeEmployment(id) {
		return HTTP({
			url: `/teachbase/activ/${id}/type_employment/`,
			method: 'GET'
		});
	},
	getVacancy(id) {
		return HTTP({
			url: `/teachbase/vacancies/${id}/`,
			method: 'GET'
		});
	},
	getCompany(id) {
		return HTTP({
			url: `/teachbase/company/${id}/`,
			method: 'GET'
		});
	},
	getCompanies() {
		return HTTP({
			url: 'teachbase/company/?in_main_page=true',
			method: 'GET'
		});
	},
	getOrganizations() {
		return HTTP({
			url: 'teachbase/activ/?in_main_page=true',
			method: 'GET'
		});
	},
	getVacanciesCompany(
		{
			id = '',
			page = 1,
			type_employment = [],
			field_activity_vacancies = [],
			specialisation_vacancies = [],
			cities_vacancies = []
		} = new Object()
	) {
		return HTTP({
			url: `/teachbase/company/${id}/vacancyes/`,
			method: 'GET',
			params: {
				page,
				vacancyes_city: cities_vacancies.length
					? cities_vacancies.join()
					: '',
				vacancyes_specialisation: specialisation_vacancies.length
					? specialisation_vacancies.join()
					: '',
				vacancyes_field_activity: field_activity_vacancies.length
					? field_activity_vacancies.join()
					: '',
				type_employment: type_employment.length
					? type_employment.join()
					: ''
			}
		});
	},
	getCompanyCities({ id = '', type_employment = [] } = new Object()) {
		return HTTP({
			url: `/teachbase/company/${id}/cityes/`,
			method: 'GET',
			params: {
				type_employment: type_employment.length
					? type_employment.join()
					: ''
			}
		});
	},
	getCompanyActivities({ id = '', type_employment = [] } = new Object()) {
		return HTTP({
			url: `/teachbase/company/${id}/field_activity/`,
			method: 'GET',
			params: {
				type_employment: type_employment.length
					? type_employment.join()
					: ''
			}
		});
	},
	getCompanySpecialisation({ id = '', type_employment = [] } = new Object()) {
		return HTTP({
			url: `/teachbase/company/${id}/specialisation/`,
			method: 'GET',
			params: {
				type_employment: type_employment.length
					? type_employment.join()
					: ''
			}
		});
	},
	getCompanyTypeEmployment(id) {
		return HTTP({
			url: `/teachbase/company/${id}/type_employment/`,
			method: 'GET'
		});
	},
	getVacancyCompanies({ company_name = '', city = '' } = new Object()) {
		return HTTP({
			url: '/teachbase/vacancy/company/',
			method: 'GET',
			params: {
				company_name,
				city
			}
		});
	},
	getSpecialisation() {
		return HTTP({
			url: '/teachbase/specialisation/',
			method: 'GET'
		});
	},
	getFieldActivity() {
		return HTTP({
			url: '/teachbase/field_activity/',
			method: 'GET'
		});
	},
	getTypeOfEmployment() {
		return HTTP({
			url: '/teachbase/type_employment/',
			method: 'GET'
		});
	},
	startCourse({ user, course, course_session }) {
		return HTTP({
			url: '/teachbase/proposal/course/',
			method: 'POST',
			headers: {
				Authorization: `Token ${getToken()}`
			},
			data: {
				user,
				course,
				course_session
			}
		});
	},
	startProgram({ program_id, session_id, user_tb_id }) {
		return HTTP({
			url: '/program/register-user/',
			method: 'POST',
			headers: {
				'Authorization': `Token ${getToken()}`
			},
			data: {
				program_id,
				session_id,
				user_tb_id
			}
		});
	},
	startVacancyXHR(data) {
		const xhr = new XMLHttpRequest();

		xhr.open(
			'POST',
			`${process.env.VUE_APP_ROOT_API}/teachbase/proposal/vacancy/`
		);
		xhr.setRequestHeader('Authorization', `Token ${getToken()}`);
		xhr.send(data);
		return xhr;
		// return HTTP({
		//     url: '/teachbase/proposal/vacancy/',
		//     method: 'POST',
		//     headers: {
		//         'Authorization': `Token ${getToken()}`,
		//         'Content-Type': 'multipart/form-data'
		//     },
		//     data
		// })
	},
	getAllVideos(params) {
		return HTTP({
			url: '/common/video/',
			method: 'GET',
			params
		});
	},
	getBlogArticles() {
		return HTTP({
			url: '/blog/articles/',
			method: 'GET'
		});
	},
	getSpecialProjects(params) {
		return HTTP({
			url: '/special_projects/',
			method: 'GET',
            params
		});
	},
	getAllResumes(uid) {
		if (!uid) {
			return HTTP({
				url: '/teachbase/summary/',
				method: 'GET',
				headers: {
					Authorization: `Token ${getToken()}`
				}
			});
		} else {
			return HTTP({
				url: '/teachbase/summary/',
				method: 'GET',
				headers: {
					Authorization: `Token ${getToken()}`
				},
				params: {
					uid
				}
			});
		}
	},
	getResume({ id, uid } = new Object()) {
		if (!uid) {
			return HTTP({
				url: `/teachbase/summary/${id}/`,
				method: 'GET',
				headers: {
					Authorization: `Token ${getToken()}`
				}
			});
		} else {
			return HTTP({
				url: `/teachbase/summary/${id}/`,
				method: 'GET',
				params: {
					uid
				}
			});
		}
	},
	createResume(data) {
		return HTTP({
			url: '/teachbase/summary/',
			method: 'POST',
			headers: {
				Authorization: `Token ${getToken()}`
			},
			data
		});
	},
	changeResume({ id, resume }) {
		return HTTP({
			url: `/teachbase/summary/${id}/`,
			method: 'PUT',
			headers: {
				Authorization: `Token ${getToken()}`
			},
			data: resume
		});
	},
	addResumeImage({ id, data }) {
		return HTTP({
			url: `/teachbase/summary/${id}/image/`,
			method: 'POST',
			headers: {
				Authorization: `Token ${getToken()}`,
				'Content-Type': 'multipart/form-data'
			},
			data
		});
	},
	removeResumeImage(id) {
		return HTTP({
			url: `/teachbase/summary/${id}/image/`,
			method: 'DELETE',
			headers: {
				Authorization: `Token ${getToken()}`
			}
		});
	},
	doubleResume(id) {
		return HTTP({
			url: `/teachbase/duplicate/${id}/`,
			method: 'POST',
			headers: {
				Authorization: `Token ${getToken()}`
			}
		});
	},
	removeResume(id) {
		return HTTP({
			url: `/teachbase/summary/${id}/`,
			method: 'DELETE',
			headers: {
				Authorization: `Token ${getToken()}`
			}
		});
	},
	//ЛИФТ БЕЗ ОТКАЗА
	sendPayment(data) {
		return HTTP({
			url: '/payments/',
			method: 'POST',
			data
		});
	},
	sendEmailSubscribe(data) {
		return HTTP({
			url: '/landing/student/',
			method: 'POST',
			data
		});
	},
	sendEmailSubscribeSchool(data) {
		return HTTP({
			url: '/landing/school_student/',
			method: 'POST',
			data
		});
	},
	sendEmailSubscribeExpert(data) {
		return HTTP({
			url: '/landing/specialist/',
			method: 'POST',
			data
		});
	},
    getActualProjects(params) {
        return HTTP({
            url: '/teachbase/actual_projects/',
            method: 'GET',
            params
        });
    },
    sendStudentOnboardingAnswers(data) {
        return HTTP({
            url: '/teachbase/setting-directions/',
            method: 'POST',
            headers: {
                Authorization: `Token ${getToken()}`
            },
            data
        });
    },
};
